import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import * as containerStyles from '~/components/pages/blog/blog.module.scss'

const NewsList = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost (
          filter: {categories: {nodes: {elemMatch: {slug: {eq: "userstory"}}}}}
          limit: 3
        ) {
          nodes {
            date(formatString: "YYYY.MM.DD")
            title
            excerpt
            content
            uri
            id
            userstory {
              userSroryData
            }
            categories {
              nodes {
                name
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const posts = allWpPost.nodes

  return (
    <div className={containerStyles.articleLatestList}>
      {posts.length >= 1 ?
        posts.map(post => {
          const title = post.title
          const userInfo = post.userstory?.userSroryData
          const featuredImage = {
            image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
            alt: post.featuredImage?.node?.alt || ``,
          }
          const category = {
            name: post.categories?.nodes[0]?.name
          }


          return (
            <article
              itemScope
              itemType="http://schema.org/Article"
              key={post.id}
              className={containerStyles.storySectionItem + " contentComponent columnComponent for-description"}
            >
              <Link to={`/blog/${post.id}`}>
                <figure>
                  <div className={containerStyles.topImgWrap + " imgWrapComponent with-border"}>
                    {featuredImage?.image ? (
                      <GatsbyImage
                        image={featuredImage.image}
                        alt={featuredImage.alt} />
                    ) : <StaticImage
                      src="../images/top_mv_pc.jpg"
                      alt=""
                      placeholder="blurred"
                      layout="fullWidth" />}
                  </div>
                  <figcaption className="heading">{userInfo}</figcaption>
                </figure>
              </Link>
              <div className="columnChild">
                <Link to={`/blog/${post.id}`}>
                  <h4 className="heading is-primary"><span itemProp="headline">{parse(title)}</span></h4>
                  <div className={containerStyles.articleExpert} itemProp="description" dangerouslySetInnerHTML={{ __html: post.content }} />
                </Link>
              </div>
            </article>
          )
        }
      ) : <p>新着記事はありません</p>}
    </div>
  )
}

export default NewsList
