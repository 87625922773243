import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import parse from "html-react-parser"
import * as containerStyles from '~/components/pages/blog/blog.module.scss'

const NewsList = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost (limit: 3) {
          nodes {
            date(formatString: "YYYY.MM.DD")
            title
            uri
            id
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    `
  )

  const posts = allWpPost.nodes

  return (
    <div className={containerStyles.articleLatestList}>
      {posts.length >= 1 ?
        posts.map(post => {
          const title = post.title
          const category = {
            name: post.categories?.nodes[0]?.name
          }

          return (
            <article
              className={containerStyles.articleItem}
              itemScope
              itemType="http://schema.org/Article"
              key={post.id}
            >
              <Link to={`/blog/${post.id}`} itemProp="url" className="contentComponent">
                <section className={containerStyles.articleDetail}>
                  <header className="mincho">
                    {category?.name && (
                      <span className={containerStyles.articleCategory}>
                        {category.name}
                      </span>
                    )}
                    <h2 className={containerStyles.articleTitle + " heading is-secondary"}>
                      <span itemProp="headline">{parse(title)}</span>
                    </h2>
                  </header>
                  <small className={containerStyles.articleDate + " mincho"}>{post.date}</small>
                </section>
              </Link>
            </article>
          )
        }
      ) : <p>新着記事はありません</p>}
    </div>
  )
}

export default NewsList
