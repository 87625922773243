import React from "react"
import { Link } from "gatsby"
import Seo from "~/components/seo"
import { StaticImage } from "gatsby-plugin-image"
import * as containerStyles from "~/components/pages/top/top.module.scss"
import Faq from "~/components/pages/top/faq"
import News from "~/components/pages/top/newslist"
import UserStory from "~/components/pages/top/userVoice"
import Vimeo from "~/components/pages/top/vimeoModal"
import Arrow from "~/images/common_icon_arrow.svg"

const IndexPage = () => (
  <>
    <Seo
      title="Shpree(シュプリ)靴たちの収納・保管・クリーニングはスマホの中に"
      pageUrl=""
    />
    <div className="home">
      <div className={containerStyles.mainVisual}>
        <div className={containerStyles.video_background}>
          <div className={containerStyles.video_foreground}>
            <iframe
              src="https://player.vimeo.com/video/628783324?autoplay=1&loop=1&muted=1"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Shpree"
            ></iframe>
          </div>
        </div>
      </div>
      <section className={containerStyles.topContent}>
        <header className={containerStyles.topLeadHeader}>
          <h2 className={containerStyles.topLeadTxt + " heading is-primary"}>
            あなたの大切な靴を未来へ
          </h2>
          <p className="heading is-primary">
            Shpreeは、プロフェッショナルな職人が靴を修理保管するサービスです。
          </p>
        </header>

        <section id="topFeature" className={containerStyles.topFeatureSection}>
          <div className="borderLine">
            <h3 className="contentComponent heading with-english">
              <span>特徴</span>Features
            </h3>
          </div>
          <div className="borderLine">
            <dl
              className={containerStyles.topFeatureList + " contentComponent"}
            >
              <div>
                <dt className="heading is-primary">
                  靴に寄り添うメンテンナンス
                </dt>
                <dd>
                  お客様それぞれの靴の状態に合わせ、職人が一足一足丁寧に、生まれ変わる靴を楽しみながら磨き上げます。Shpreeでは靴に寄り添うメンテナンスを常に心がけています。
                </dd>
              </div>
              <div>
                <dt className="heading is-primary">
                  職人と直接のやり取りで安心
                </dt>
                <dd>
                  お客様の大切な靴を安心してお預けいただけるよう、LINEやメールなどを介して職人に直接ご相談していただけるシステムを導入しています。ご不安な点などございましたらお気軽にお問い合わせください。
                </dd>
              </div>
              <div>
                <dt className="heading is-primary">環境へ配慮した素材</dt>
                <dd>
                  環境にも人にも優しい最高級靴クリームを使用し、お客様それぞれの靴の素材や色に合わせたメンテナンスを行っています。
                </dd>
              </div>
            </dl>
          </div>
        </section>

        <section
          id="topBeforeAfter"
          className={containerStyles.beforeAfterSection}
        >
          <header className="borderLine">
            <h3 className="contentComponent heading with-english">
              <span>お客さま事例</span>Before / After
            </h3>
          </header>
          <div className="contentComponent columnComponent is-2colmun">
            <section className="columnChild">
              <div
                className={
                  containerStyles.topImgWrap + " imgWrapComponent with-border"
                }
              >
                <StaticImage
                  src="../images/top_before_image.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                Before
              </h4>
              <p>
                ほこりやカビが靴底に生えてしまっていた状態。長らく靴箱に放置していたことで革に栄養がなく、シューシャインを申込みされました。
              </p>
            </section>
            <section className="columnChild">
              <div
                className={
                  containerStyles.topImgWrap + " imgWrapComponent with-border"
                }
              >
                <StaticImage
                  src="../images/top_after_image.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                After
              </h4>
              <p>
                シューツリーで靴型を整え、ほこりやカビを丁寧に手作業で落としていきます。革に栄養と補色を入れ込むために、高級クリームで丁寧に磨きあげました。また、靴底のカビも丁寧に取り除き、さらに靴専用のウイルス殺菌機で除去。靴底・コバまわりも磨きをかけ、本来の革のもつ素材が活きるようにシューシャインを施しました。
              </p>
            </section>
          </div>
        </section>

        <section id="topUserFlow" className={containerStyles.flowSection}>
          <header className="borderLine">
            <h3 className="contentComponent heading with-english">
              <span>ご利用の流れ</span>User's Flow
            </h3>
          </header>
          <div className="contentComponent columnComponent is-2colmun">
            <section className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_flow_1.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                ネットでご注文＆集荷依頼メール到着
              </h4>
              <p>
                ご注文画面よりご希望のサービスをお選びください。決済まで終えて頂くと、ご注文完了メールと、お靴を発送するための集荷依頼メールが届きます。（届いていない場合は、迷惑メールフォルダなどご確認をお願い致します。また靴をお送りいただく際には、お手数ですがお客様に箱をご準備いただく形となります）
              </p>
            </section>
            <section className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_flow_2.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                靴を工房へ発送
              </h4>
              <p>
                集荷依頼メールの画面に沿って、発送する場所をご選択ください。ご自宅へ直接お伺いして（最短翌日から）集荷させて頂くことも可能です。もしくは全国の集荷スポット(クロネコヤマト、提携コンビニ、PUDロッカー)に当日からお持ち込み頂く事も可能です。お手持ちの箱に入れてShpreeまでお送りください（靴の箱は返送時にShpree専用の箱でお送りいたします。お送りいただいた箱や袋は返却できかねます。また、シューキーパーも発送時に外していただきますようお願いいたします）。
              </p>
            </section>
            <section className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_flow_3.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                修理・メンテナンス
              </h4>
              <p>
                工房へ到着した靴は、職人によって状態を見極めながら、素材を痛めないようご希望の修理やメンテナンスが施されます（おまかせプラン含め、靴の状態を拝見後に職人がお客様にご連絡をさしあげる場合がございます。ご登録いただく連絡先は、恐れ入りますがご連絡がとれる情報でお願いいたします）。
              </p>
            </section>
            <section className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_flow_4.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <h4
                className={containerStyles.topHeading + " heading is-primary"}
              >
                お客様の元へ発送
              </h4>
              <p>
                メンテナンスが完了した靴を、お客様の元へお送り致します。Sphreeを通して、お客様がお気に入りの靴とこれからも素敵な時間を歩んでいけますように。
              </p>
            </section>
          </div>
        </section>
      </section>

      <div className={containerStyles.topVimeoLink}>
        <Vimeo />
      </div>

      <section className={containerStyles.planSection}>
        <div id="topPlan" className="ankerTarget">
          <header className="borderLine">
            <h3 className="contentComponent heading with-english">
              <span>料金プラン</span>Plan
            </h3>
          </header>
        </div>
        <div className="contentComponent">
          <section className="columnComponent is-2colmun">
            <Link to="/order/" className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_plan_image_a.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
            </Link>
            <div className="columnChild">
              <header
                className={containerStyles.topHeading + " heading is-primary"}
              >
                <h4>
                  <Link to="/order/">
                    <span className={containerStyles.topHeadingSub}>
                      Plan - A
                    </span>
                    <span>修理</span>
                  </Link>
                </h4>
              </header>
              <p>
                それぞれの修理箇所に合うお色で対応させていただきます。その他の修理はおまかせプランとなりますが、ご注文時にお写真をお送りいただければ、職人が靴の状態を確認して事前にお見積りをお送りさせていただきます。
              </p>
              <Link to="/order/">
                <table>
                  <tbody>
                    <tr>
                      <th>ヒールお直し</th>
                      <td>3,300円（税込）</td>
                    </tr>
                    <tr>
                      <th>かかと修理</th>
                      <td>5,500円（税込）</td>
                    </tr>
                    <tr>
                      <th>オールソール</th>
                      <td>14,300円〜（税込）</td>
                    </tr>
                    <tr>
                      <th>ハーフラバー</th>
                      <td>5,500円（税込）</td>
                    </tr>
                  </tbody>
                </table>
              </Link>
            </div>
          </section>

          <section className="columnComponent is-2colmun">
            <Link to="/order/" className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_plan_image_b.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
            </Link>
            <div className="columnChild">
              <header
                className={containerStyles.topHeading + " heading is-primary"}
              >
                <Link to="/order/">
                  <h4>
                    <span className={containerStyles.topHeadingSub}>
                      Plan - B
                    </span>
                    <span>クリーニング</span>
                  </h4>
                </Link>
              </header>
              <p>
                お客様それぞれの靴の状態に合わせ、職人が一足一足丁寧に、生まれ変わる靴を楽しみながら磨き上げます。環境にも人にも優しい最高級靴クリームを使用し、靴の素材や色に合わせたメンテナンスを行っています。
              </p>
              <Link to="/order/">
                <table>
                  <tbody>
                    <tr>
                      <th>シューシャイン</th>
                      <td>3,300円（税込）</td>
                    </tr>
                  </tbody>
                </table>
              </Link>
            </div>
          </section>

          <section className="columnComponent is-2colmun">
            <Link to="/order/" className="columnChild">
              <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
                <StaticImage
                  src="../images/top_plan_image_c.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
            </Link>
            <div className="columnChild">
              <header
                className={containerStyles.topHeading + " heading is-primary"}
              >
                <Link to="/order/">
                  <h4>
                    <span className={containerStyles.topHeadingSub}>
                      Plan - C
                    </span>
                    <span>保管</span>
                  </h4>
                </Link>
              </header>
              <p>
              あなたの大切な靴を職人が最適な環境下でお預かり致します。出し入れは、スマホから自由自在。もし、今の状態が汚れていたりカビが生えていても大丈夫。職人が「靴磨き」をして「ウイルス殺菌・抗菌・脱臭」をしてから保管させて頂くコースもご用意しておりますので、ご安心してお預けいただけます。
              </p>
              <Link to="/order/">
                <table>
                  <tbody>
                    <tr>
                      <th>保管</th>
                      <td>１足あたり　660円（税込）/月＋オプション</td>
                    </tr>
                  </tbody>
                </table>
              </Link>
            </div>
          </section>
        </div>
      </section>
      <section id="topFaq" className={containerStyles.faqSection}>
        <header>
          <h3 className="contentComponent heading with-english">
            <span>よくあるご質問</span>FAQ
          </h3>
        </header>
        <div className="contentComponent">
          <Faq />
          <footer>
            <div className="moreLinkWrap">
              <Link to="/pages/faq/" className="moreLink">
                <span className="heading is-secondary">もっと見る</span>
                <Arrow />
              </Link>
            </div>
          </footer>
        </div>
      </section>

      <section id="topUserStory" className={containerStyles.storySection}>
        <header className="borderLine">
          <h3 className="contentComponent heading with-english">
            <span>お客さまの靴のストーリー</span>User's Story
          </h3>
        </header>
        <UserStory />
      </section>

      <section id="topMessage" className={containerStyles.messageSection}>
        <header className="borderLine">
          <h3 className="contentComponent heading with-english">
            <span>代表メッセージ</span>Message
          </h3>
        </header>
        <section
          className={
            containerStyles.messageSectionItem +
            " contentComponent columnComponent for-description"
          }
        >
          <div className={containerStyles.topImgWrap + " imgWrapComponent"}>
            <StaticImage
              src="../images/top_message_image.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="columnChild">
            <h4 className="heading is-primary">
              靴職人でもある私たちは、お預かりした靴を手に取るとき、
              <br />
              お客様の人生を想像します。
            </h4>
            <p>
              私たちShpree（シュプリ）の前身は、1954年、鳥取の小さな町に誕生した靴屋です。靴職人であった祖母の日々の口ぐせは「一足一足を、そしてお客様おひとりおひとりを大切に」。この言葉は、時代を経た今もShpreeの信条として息づいています。今は昔と違い、おひとりで何足もの靴を所有する時代です。それでも靴が、人の毎日と共にあることは変わりありません。仕事へ、遊びへ、習い事へ、大切な人に会いに…。
              <br />
              靴は人に寄り添い、人生を前に進めてくれる相棒だと考えるShpreeは、「近所の職人さんが引退されて、わざわざ○○（遠方）から来ました」「久々に履こうと靴箱を開けたらカビが生えていた」など、シューシャインやリペアをお受けする中で聞こえてきたそんなお客様の声に、時代に合ったサービスをご提供できないかと考えました。
            </p>
            <p>
              そうして誕生したのが、自宅にいながら靴のメンテナンスを職人と会話するようにお願いできるWebサービスです。お気に入りの靴と長く歩んでいただけるよう、靴職人集団ならではの強みを生かし、ご相談（職人におまかせプラン）も受けたまわります。お預かりした履物が、お客さまの毎日の中に出番を迎えるその日まで、一足一足を大切に寄り添いメンテナンスいたします。
            </p>
            <footer className={containerStyles.messageFooter}>
              <p className="heading">
                株式会社 SHPREE
                <br />
                代表取締役社長 岸田将志
              </p>
              <div className={containerStyles.messageSign}>
                <StaticImage
                  src="../images/common_pict_sign.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
            </footer>
          </div>
        </section>
      </section>

      <section id="topNews" className={containerStyles.newsSection}>
        <header className="borderLine">
          <h3 className="contentComponent heading with-english">
            <span>お知らせ</span>News
          </h3>
        </header>
        <News />
        <footer className="contentComponent">
          <div className="moreLinkWrap">
            <Link to="/blog/" className="moreLink">
              <span className="heading is-secondary">もっと見る</span>
              <Arrow />
            </Link>
          </div>
        </footer>
      </section>
    </div>
  </>
)

export default IndexPage
