import React from 'react'

const Faq = () => (
  <>
    <div className="acordionComponent">
      <section className="faqAcordionWrap">
        <h3 className="acordionSwitch heading is-secondary">受け付けていない靴はありますか？</h3>
        <div className="acordionContent">
          <div className="faqDetail">
            Shpreeでは、長靴やサンダルなどお受けできない種類の靴がいくつかございます。また、明らかに破損している靴の場合は、そのままご返却させていただくことがございます。ご不安な際はご注文いただく前に一度メールもしくはLINEでお問い合わせください。
          </div>
        </div>
      </section>

      <section className="faqAcordionWrap">
        <h3 className="acordionSwitch heading is-secondary">靴の幅を伸ばすことはできますか？</h3>
        <div className="acordionContent">
          <div className="faqDetail">
            靴の幅を伸ばすことはできますが、お客様の足幅に合わせた細かな調整が必要になるため、基本的には店頭で直接お預かりできるお靴のみ承っております。
          </div>
        </div>
      </section>

      <section className="faqAcordionWrap">
        <h3 className="acordionSwitch heading is-secondary">カビだらけになってしまった靴でも大丈夫ですか？</h3>
        <div className="acordionContent">
          <div className="faqDetail">
            カビだらけになってしまった靴でも磨かせていただきます。ただ、カビの根元が深い場合は、磨いたあとも跡が残ってしまう可能性がございます。その点をご了承いただいた上でお申し込みいただけますと幸いです。
          </div>
        </div>
      </section>

    </div>
  </>
)

export default Faq
