import React, { useState } from 'react'
import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

const VimeoModal = () => {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  function modalOpen(e) {
    e.preventDefault();
    console.log('You clicked submit.');
  }

  const modalStyle = {
    overlay: {
      zIndex: "99999",
      backgroundColor: "black"
    },
    content: {
      padding: 0,
      zIndex: "99999",
      border: 0,
      borderRadius: 0,
      backgroundColor: "transparent"
    }
  };

  return (
    <>
      <button type="button" onClick={() => setIsOpen(true)}>
        <div className="topVimeoImage"></div>
      </button>

      <Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modalIframeWrap">
          <iframe src="https://player.vimeo.com/video/248264800?autoplay=1&loop=1&muted=1" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title=""></iframe>
        </div>
      </Modal>
    </>
  )
}

export default VimeoModal
